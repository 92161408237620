export default `This is TURO, a calculator notepad.
This whole page is editable. Your calculations update automatically.
1+2*3
0.1 + 0.2

It understands units…
100m / 10s in mph
4 inches * sin(45 degrees)

and variables
radius = 4 cm
area = pi * radius^2
volume = 4/3 * pi * radius^3

Expressions on the one side of the screen, results on the other. Simples.
Want to try? EDIT THIS PAGE.

For example, try putting in your own numbers here to find your body mass index:
height = 5 ft + 10 inches
mass = 76 kg
bmi = mass kg / (height m)^2

Show your working? Share the link.
Save your working? Bookmark it.`
